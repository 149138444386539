import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useReducer, useState } from 'react';
import { Button, Card, Container, Dimmer, Embed, Grid, Header, Icon, Image, Loader, Modal, Placeholder, Segment } from 'semantic-ui-react';
import { TopNav, TopNavItems } from '../components/TopNav';
import { config } from '../config';
import { ApiRefreshResponse } from '../models/ApiRefreshResponse';
import { ApiUrl } from '../models/ApiUrl';
import { ApiVideo } from '../models/ApiVideo';
import { ApiVideoDay } from '../models/ApiVideoDay';
import { ApiVideos } from '../models/ApiVideos';
import { DayVideoId } from '../models/DayVideoId';
import styles from './VideosPage.module.css';

enum Action {
  ReplaceAll,
  UpdateSingleVideo,
  MergeAll
}

interface VideoAction {
  actionName: Action;
  replace?: ApiVideoDay[];
  merge?: ApiVideoDay[];
  update?: {
    dayVideoId: DayVideoId;
    video: ApiVideo;
  }
}

const initialState: ApiVideoDay[] = []

function videoReducer(state: ApiVideoDay[], action: VideoAction): ApiVideoDay[] {
  const { actionName, replace, merge, update } = action;
  switch (actionName) {
    case Action.ReplaceAll:
      return replace!;
    case Action.MergeAll:
      if (!merge) {
        return state;
      }
      return [
        ...state.map(day => {
          const mergeDay = merge.find(md => md.date == day.date);
          if (mergeDay) {
            // First update the existing videos
            const videosWithUpdate = day.videos.map(v => {
              // Replace the existing video with the merge version if it exists, else leave it unchanged
              return mergeDay.videos.find(mv => v.id == mv.id) || v;
            })
            // Then add any videos which aren't already in place
            const videosNew = mergeDay.videos.filter(mv => !videosWithUpdate.some(v => v.id == mv.id));
            // Finally sort it (might not be necessary, but safest)
            const videosSorted = [ ...videosWithUpdate, ...videosNew ].sort((a, b) => b.id - a.id)
            return {
              ...day,
              videos: videosSorted
            }
          } else {
            return day;
          }
        }),
        ...merge.filter(md => !state.some(d => d.date == md.date))
      ].sort((a, b) => b.date - a.date);
    case Action.UpdateSingleVideo:
      return state.map(day => {
        if (day.date == update!.dayVideoId.dayDate) {
          const updatedVideos = day.videos.map(v => {
            if (v.id == update!.dayVideoId.videoId) {
              return update!.video;
            } else {
              return v;
            }
          })
          return {
            ...day,
            videos: updatedVideos
          }
        } else {
          return day;
        }
      });
    default:
      console.warn(`Unknown action ${actionName}`);
      return state;
  }
}

const VideosPage = () => {
  const [days, dispatch] = useReducer(videoReducer, initialState);
  const [downloadingVideoId, setDownloadingVideoId] = useState<number>();
  const [nextKey, setNextKey] = useState<string>();
  const [loadingNextKey, setLoadingNextKey] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [showVideo, setShowVideo] = useState<ApiVideo>();
  const [showVideoUrl, setShowVideoUrl] = useState<string>();

  const { getAccessTokenSilently } = useAuth0();

  const { api, auth0 } = config;

  // Load videos on startup
  useEffect(() => {
    getAccessTokenSilently({
      audience: auth0.audience,
    }).then(accessToken =>
    fetch(
      `${api.url}/secure/videos/page`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      },
    )
      .then(res => res.json())
      .then((response: ApiVideos) => {
        dispatch({ actionName: Action.ReplaceAll, replace: response.days });
        setNextKey(response.next);
        setIsLoading(false);
        if (response.refreshDue) {
          // The videos are due to be refreshed
          triggerRefresh();
        } else if (!response.ready) {
          // Some videos haven't been loaded yet, so send requests to load them
          sync(response.days);
        }
      }))
      .catch(error => console.log(error));
  }, [getAccessTokenSilently]);

  // Load the video URL whenever the popup appears
  useEffect(() => {
    setShowVideoUrl(undefined);
    if (showVideo) {
      getAccessTokenSilently({
        audience: auth0.audience,
      }).then(accessToken =>
        fetch(
          `${api.url}/secure/videos/${showVideo.id}/url`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${accessToken}`,
            }
          },
        )
          .then(res => res.json())
          .then((response: ApiUrl) => {
            setShowVideoUrl(response.url);
          })
          .catch(error => console.log(error)));
    }
  }, [showVideo]);

  // Load more videos whenever the key is set
  useEffect(() => {
    if (!loadingNextKey) {
      return;
    }
    getAccessTokenSilently({
      audience: auth0.audience,
    }).then(accessToken =>
        fetch(
          `${api.url}/secure/videos/page?key=${loadingNextKey}`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${accessToken}`,
            }
          },
        )
          .then(res => res.json())
          .then((response: ApiVideos) => {
            dispatch({ actionName: Action.MergeAll, merge: response.days });
            setNextKey(response.next);
            setLoadingNextKey(undefined);
            setIsLoading(false);
            if (response.refreshDue) {
              // The videos are due to be refreshed
              triggerRefresh();
            } else if (!response.ready) {
              // Some videos haven't been loaded yet, so send requests to load them
              sync(response.days);
            }
          }))
      .catch(error => console.log(error));
  }, [loadingNextKey]);

  // // Auth0 test
  // useEffect(() => {
  //   const getUserMetadata = async () => {
  //     try {
  //       const accessToken = await getAccessTokenSilently({
  //         audience: auth0.audience,
  //         scope: "read:videos",
  //       });
  //
  //       const userDetailsByIdUrl = `https://${auth0.domain}/api/v2/users/${user?.sub}`;
  //
  //       const metadataResponse = await fetch(userDetailsByIdUrl, {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //         },
  //       });
  //
  //       const { user_metadata } = await metadataResponse.json();
  //
  //       setUserMetadata(user_metadata);
  //     } catch (e: any) {
  //       console.log(e.message);
  //     }
  //   };
  //
  //   getUserMetadata();
  // }, [getAccessTokenSilently, user?.sub]);

  const popupVideo = (video: ApiVideo) => {
    return (_event: React.MouseEvent<HTMLElement>) => {
      if (video.ready) {
        setShowVideo(video);
      }
    };
  };

  const downloadVideo = (video: ApiVideo) => {
    return (event: React.MouseEvent<HTMLElement>) => {
      console.log(`Download video ${video.id}`);
      setDownloadingVideoId(video.id);
      getAccessTokenSilently({
        audience: auth0.audience,
      }).then(accessToken =>
        fetch(
          `${api.url}/secure/videos/${video.id}/download`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${accessToken}`,
            }
          },
        )
          .then(res => res.json())
          .then((response: ApiUrl) => {
            setDownloadingVideoId(undefined);
            window.location.href = response.url;
          })
          .catch(error => console.log(error)));
      event.stopPropagation();
    };
  };

  const triggerRefresh = async () => {
    console.info(`Triggering a refresh`);
    setIsRefreshing(true);
    getAccessTokenSilently({
      audience: auth0.audience,
    }).then(accessToken =>
      fetch(
        `${api.url}/secure/videos/refresh`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          }
        },
      )
        .then(res => res.json())
        .then((refreshResponse: ApiRefreshResponse) => {
          if (refreshResponse.refreshed && refreshResponse.videos) {
            const { videos } = refreshResponse;
            dispatch({ actionName: Action.MergeAll, merge: videos.days });
            if (!videos.ready) {
              // Some videos haven't been loaded yet, so send requests to load them
              sync(videos.days);
            }
          }
          setIsRefreshing(false);
        })
        .catch(error => {
          console.log(error);
          setIsRefreshing(false);
        }));
  }

  const sync = async (days: ApiVideoDay[]) => {
    const unreadyVideos: DayVideoId[] = [];
    for (const day of days) {
      for (const video of day.videos) {
        if (!video.ready && video.id) unreadyVideos.push({
          dayDate: day.date,
          videoId: video.id,
        });
      }
    }
    console.debug(`Downloading ${unreadyVideos.length} unready videos`);
    syncNextVideo(unreadyVideos.reverse());
  }

  const syncNextVideo = async (dayVideoIds: DayVideoId[]) => {
    const dayVideoId = dayVideoIds.pop();
    if (dayVideoId) {
      getAccessTokenSilently({
        audience: auth0.audience,
      }).then(accessToken =>
        fetch(
          `${api.url}/secure/videos/${dayVideoId.videoId}/sync`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          },
        )
          .then(res => res.json())
          .then((video: ApiVideo) => {
            dispatch({
              actionName: Action.UpdateSingleVideo,
              update: {
                dayVideoId,
                video,
              },
            });
            syncNextVideo(dayVideoIds);
          })
          .catch(error => console.log(error)));
    }
  };

  useEffect(() => {
    const onScroll = function () {
      if (100 + window.innerHeight + window.scrollY >= document.body.scrollHeight) {
        if (nextKey && !loadingNextKey) {
          setLoadingNextKey(nextKey);
        }
      }
    }
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [nextKey, loadingNextKey])

  return (
    <div>
      <TopNav item={TopNavItems.Videos} />
      <Container style={{ marginTop: '5em' }}>

        <Modal
          basic
          onClose={() => setShowVideo(undefined)}
          open={!!showVideo}
          size='fullscreen'
        >
          {showVideo && (
            <>
              <Header>
                {showVideo && `${showVideo.date} ${showVideo.time}`}
              </Header>
              <Modal.Content>
                {showVideoUrl ? (
                  <div>
                    <video controls autoPlay={true} className={styles.fullscreenvideo}>
                      <source src={showVideoUrl} />
                    </video>
                  </div>
                ) : (
                  <Dimmer.Dimmable dimmed={true}>
                    <Dimmer active={true}>
                      <Loader size={'large'}>Loading video</Loader>
                    </Dimmer>
                    <Embed
                      icon=''
                      placeholder={showVideo.imageUrl}
                    />
                  </Dimmer.Dimmable>
                )}
              </Modal.Content>
              <Modal.Actions>
                <Button color='grey' inverted onClick={downloadVideo(showVideo)}>
                  <Icon name='download' /> Download
                </Button>
                <Button color='blue' inverted onClick={() => setShowVideo(undefined)}>
                  <Icon name='checkmark' /> Close
                </Button>
              </Modal.Actions>
            </>
          )}

        </Modal>
        <Segment basic>
          <Grid>
            <Grid.Row>
              <Grid.Column width='14'>
                <Header as='h1'>Security videos</Header>
              </Grid.Column>
              <Grid.Column width='2'>
                { (isLoading || isRefreshing) && (
                  <Loader active>Loading...</Loader>
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        {!isLoading && days.map((day: ApiVideoDay) => (
          <Segment basic key={day.date}>
            <Header as='h3' block>
              {day.title}
            </Header>
            <Card.Group>
              {day.videos.map(video => video.ready ? (
                <Card key={video.id} onClick={popupVideo(video)}>
                  <Image
                    wrapped ui={false}
                    src={video.imageUrl}
                  />
                  <Card.Content>
                    <Card.Header>{video.time}</Card.Header>
                    <Card.Meta>{video.date}</Card.Meta>
                  </Card.Content>
                  <Card.Content textAlign='right' extra onClick={downloadVideo(video)}>
                    <Icon name={(downloadingVideoId == video.id) ? 'wait' : 'download'} />
                    {video.size}
                  </Card.Content>
                </Card>
              ) : (
                <Card key={video.id}>
                  <Placeholder>
                    <Placeholder.Image rectangular className={styles.wideplaceholder} />
                  </Placeholder>
                  <Card.Content>
                    <Dimmer active>
                      <Loader inverted>Loading</Loader>
                    </Dimmer>
                    <Card.Header>{video.time}</Card.Header>
                    <Card.Meta>{video.date}</Card.Meta>
                  </Card.Content>
                  <Card.Content textAlign='right' extra>
                    <Icon name='download' disabled />
                    {video.size}
                  </Card.Content>
                </Card>
              ))}
            </Card.Group>
          </Segment>
        ))}
        {!isLoading && nextKey && (
          <Segment basic>
            <Button fluid onClick={() => setLoadingNextKey(nextKey)} disabled={!!loadingNextKey}>Load more videos...</Button>
          </Segment>
        )}
      </Container>

    </div>
  );
};

export default VideosPage;
