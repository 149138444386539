import { useAuth0, User } from '@auth0/auth0-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Dropdown, Menu } from 'semantic-ui-react';
import { config } from '../config';
import { RouteConfig } from '../resources/routes-constants';


type TopNavProps = {
  item?: TopNavItems;
};

export enum TopNavItems {
  Home,
  Videos
}

export const TopNav = ({ item }: TopNavProps) => {
  const { isLoading, user, isAuthenticated, logout } = useAuth0();
  const authUser: User | undefined = !isLoading && isAuthenticated ? user : undefined;
  return (
    <Menu fixed='top' inverted>
      <Container>
        <Menu.Item as='a' header>
          Morrisdex
        </Menu.Item>
        <Menu.Item as={Link} to={RouteConfig.Homepage} active={item == TopNavItems.Home}>Home</Menu.Item>
        <Menu.Item as={Link} to={RouteConfig.Videos} active={item == TopNavItems.Videos}>Videos</Menu.Item>
        { authUser && (
          <Dropdown item text={authUser.nickname} className='right'>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => logout({ returnTo: config.auth0.logoutUrl })}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </Container>
    </Menu>
  );
};
