import { withAuthenticationRequired } from '@auth0/auth0-react';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import './global.css';
import HomePage from './pages/HomePage';
import NotFoundPage from './pages/NotFoundPage';
import VideosPage from './pages/VideosPage';

import { RouteConfig } from './resources/routes-constants';

const RootComponent: React.FC = () => {

  const AuthenticatedVideos = withAuthenticationRequired(VideosPage);

  return (
    <Router>
      <Routes>
        <Route path='*' element={<NotFoundPage />} />
        <Route path={RouteConfig.Homepage} element={<HomePage />} />
        <Route path={RouteConfig.Videos} element={<AuthenticatedVideos />} />
      </Routes>
    </Router>
  );
};

export default RootComponent;
