import { useAuth0, User } from '@auth0/auth0-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Segment } from 'semantic-ui-react';
import { TopNav, TopNavItems } from '../components/TopNav';
import { config } from '../config';
import { RouteConfig } from '../resources/routes-constants';

const HomePage: React.FC = () => {
  const { loginWithRedirect, user, isAuthenticated, isLoading, logout } = useAuth0();
  const authUser: User | undefined = !isLoading && isAuthenticated ? user : undefined;

  return (
    <div className='taminickpainting'>
      <TopNav item={TopNavItems.Home} />
      <Container className='homepagecontainer'>
        <h1>Morris family website</h1>
        <p>Welcome to Morrisdex, our family website. Right now you can access security videos and soon we&apos;ll add more information such as contact details.
        Contact Charlie for access and for help with any problems you encounter.</p>
        <Segment basic>
          { authUser ? (
            <>
            <Button primary onClick={() => logout({ returnTo: config.auth0.logoutUrl })}>
              Log Out
            </Button>
              <b>You&apos;re logged in as {authUser.nickname}. <Link to={RouteConfig.Videos}>Go to the videos</Link></b>
            </>
          ) : (
            <>
              <Button primary onClick={() => loginWithRedirect({ redirectUri: config.auth0.redirectAfterLogin })}>Log In</Button>
              <b>Please log in to view videos from the security camera.</b>
            </>
          )}
        </Segment>
      </Container>
    </div>
  );
};

export default HomePage;
