
interface Config {
  api: {
    url: string;
  },
  auth0: {
    audience: string;
    clientId: string;
    domain: string;
    redirectAfterLogin: string;
    logoutUrl: string;
  }
}

export const config: Config = {
  api: {
    url: process.env.REACT_APP_API_URL || 'https://q6vnv66reh.execute-api.ap-southeast-2.amazonaws.com',
  },
  auth0: {
    audience: 'https://morrisdex.net.au/',
    clientId: 'PqduOr1zs6FlGmeKINsKEy71UaKvOlEL',
    domain: 'morris.au.auth0.com',
    redirectAfterLogin: process.env.REACT_APP_REDIRECT_URL || 'https://morrisdex.net.au/videos',
    logoutUrl: process.env.REACT_APP_LOGOUT_URL || 'https://morrisdex.net.au'
  }
}
